import React, { useCallback, useEffect, useState } from 'react';
import IPage from '../interfaces/page';
import IProduto from '../interfaces/produto';
import { Button, Container, Fab, IconButton, Paper, Typography, Zoom } from '@material-ui/core';
import sProdutos from '../services/produtoService';
import sNba from '../services/nbaService';
import { useUserContext } from '../authentication/userContextProvider';
import ModalConfirm, { ModalInfo, ModalOptionals } from '../components/modal';
import Message from '../config/message';
import { useMessage } from '../messages/context';
import { ExitToAppOutlined } from '@material-ui/icons';
import AccordionProdutos from '../components/accordionProdutos';
import IInformacoesQuantidadeProdutos, { InitialValues } from '../interfaces/IInformacoesQuantidadeProdutos';
import aut2FatService from '../services/aut2FatService';
import ModalAut2fat, { ModalMessage } from '../components/modalAut2fat/index';
import IOptionalsData, { IOptionalChosen } from '../interfaces/IOptionalsData';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

const PartnersPage: React.FunctionComponent<IPage> = function (props) {
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false | undefined>(undefined);

  const handleChangeAccordionExpanded =
    (panel: string) => (event: React.ChangeEvent<{}>, newAccordionExpanded: boolean) => {
      setAccordionExpanded(newAccordionExpanded ? panel : false);
    };

  const { user, logout = () => {} } = useUserContext();
  const { showAlertApp = () => {}, showAlertApi = () => {} } = useMessage();
  const [isActionButton, setIsActionButton] = React.useState<boolean>(false);

  const [produtosNaoElegiveis, setProdutosNaoElegiveis] = useState<IProduto[]>([]);

  const [alteracoesHabilitadas, setAlteracoesHabilitadas] = useState<boolean>(true);

  const [modalConfirmacaoOpen, setModalConfirmacaoOpen] = useState<boolean>(false);
  const [modalConfirmacaoInformacoes, setModalConfirmacaoInformacoes] = useState({
    headerText: '',
    bodyStandard: [],
    bodyPremium: [],
  });

  const [modalInfoProdutoOpen, setModalInfoProdutoOpen] = useState<boolean>(false);
  const [produtoSelecionadoModalInfo, setProdutoSelecionadoModalInfo] = useState<IProduto | null>(null);
  const [produtoSelecionadoDescricaoModalInfo, setProdutoSelecionadoDescricaoModalInfo] = useState<string>('');
  const [produtoSelecionadoUrlModalInfo, setProdutoSelecionadoUrlModalInfo] = useState<string>('');

  const [modalMessageOpen, setModalMessageOpen] = useState<boolean>(false);
  const [intTextModalMessage, setIntTextModalMessage] = useState<string>('');
  const [intTitleModalMessage, setIntTitleModalMessage] = useState<string>('');
  const [attentionMessage, setAttentionMessage] = useState<string>('');
  const [bodyTextContent, setBodyTextContent] = useState<string>('');
  const [modalAutenticacaoOpen, setModalAutenticacaoOpen] = useState<boolean>(false);
  const [responseReenvio, setResponseReenvio] = useState<string>('');
  const [optionalsFetchData, setOptionalsFetchData] = useState<Array<IOptionalsData>>([]);
  const [modalOptionalChosen, setModalOptionalChosen] = useState<Array<IOptionalChosen>>([]);
  const [modalOptionalsData, setModalOptionalsData] = useState<IOptionalsData>({ type: '', data: [] });

  const refreshProdutos = useCallback(() => {
    sProdutos.listaProdutosNBA().then((resp) => {
      const {
        itensNaoElegiveis,
        itensStandard,
        itensPremium,
        habilitado,
        mensagem,
        mensagemConfirmacao,
      } = resp.data;

      setProdutosNaoElegiveis(itensNaoElegiveis);

      const allProducts = [...itensNaoElegiveis, ...itensStandard, ...itensPremium];
      fetchListOptionals(allProducts);
      

      setAlteracoesHabilitadas(habilitado);
      showAlertApi(mensagem, 'info', 10_000);

      setModalConfirmacaoInformacoes({
        ...modalConfirmacaoInformacoes,
        headerText: mensagemConfirmacao,
      });
    });
  }, []);

  useEffect(() => {
    refreshProdutos();
  }, []);

  const formatDate = (input: any) => {
    const datePart = input?.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2],
      hora = datePart[3],
      min = datePart[4];
    return day + '/' + month + '/' + year + ' às ' + hora + ':' + min;
  };

  const handleSelectProduto = (
    e: React.ChangeEvent<HTMLInputElement>,
    produtoCategoriaDisponivel: number,
    setStateProduto: (prevState: any) => void,
    codigoSVA: string,
  ) => {
    if (produtoCategoriaDisponivel <= 0 && e.target.checked) {
      showAlertApp(Message.maximoQuantidadePacotes, 'warning', 3500);
      return;
    }

    setStateProduto((prevState: any) => {
      const updatedSva = [...prevState];
      const index = updatedSva.findIndex((prod) => prod.descricao === codigoSVA);
      const produto = updatedSva[index];

      produto.selecionado = e.target.checked;
      produto.alterado = produto.alterado == null || produto.alterado == false;

      return updatedSva;
    });

  };

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsActionButton(true);
    } else {
      setIsActionButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const verifyAut2Fat = (operacao: string) => {
    //debugger
    aut2FatService.getCodigoAut2Fat({ PaAdfOperacao: operacao, modeloEnvio: "Sms" }).then((resp) => {
      //debugger
      if (resp.data) {
        // debugger;
        if (resp.data.situacaoLiberacao === 'Falha') {
          showAlertApi(resp.statusText, 'error', resp.status);
          return;
        } else if (resp.data.paAdfOperacao === 'Abrir') {
          if (
            resp.data.situacaoLiberacao === 'ProcessoLiberado' ||
            resp.data.situacaoLiberacao === 'IspLiberada' ||
            resp.data.situacaoLiberacao === 'ClienteAutenticado'
          ) {

            setModalConfirmacaoOpen(false);
            
            refreshProdutos();
            setModalAutenticacaoOpen(false);

          } else if (resp.data.situacaoLiberacao === 'AutenticacaoExigida') {
            if (resp.data.situacaoEnvio === 'Desnecessario') {
              showAlertApi(resp.statusText, 'error', resp.status);
              return;
            } else if (resp.data.situacaoEnvio === 'Concorrente' || resp.data.situacaoEnvio === 'Precedente') {
              //debugger
              if (resp.data.restanteEnvio !== undefined) {
                if (resp.data.restanteEnvio !== 1) {
                  setAttentionMessage('Restam ' + resp.data.restanteEnvio + ' envios de autenticação este mês.');
                } else {
                  setAttentionMessage('Resta ' + resp.data.restanteEnvio + ' envio de autenticação este mês.');
                }
              } else {
                setAttentionMessage('');
              }
              // Texto que apareça no body
              if (resp.data.situacaoEnvio === 'Concorrente') {
                setBodyTextContent(
                  'Um código de autenticação foi enviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    '. Por favor digite esse código no campo abaixo.',
                );
              } else if (resp.data.situacaoEnvio === 'Precedente') {
                setBodyTextContent(
                  'Um código de autenticação foi enviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    ' em ' +
                    formatDate(resp.data.dataEnvio) +
                    '. Por favor digite esse código no campo abaixo.',
                );
              }

              if (resp.data.situacaoReenvio === 'Nenhum') {
                setResponseReenvio('');
              } else if (resp.data.situacaoReenvio === 'Antigo') {
                setResponseReenvio('reenviar código');
              } else if (resp.data.situacaoReenvio === 'Novo') {
                setResponseReenvio('enviar novo código');
              }

              setModalAutenticacaoOpen(true);
            } else if (resp.data.situacaoEnvio === 'LimiteCliente') {
              setIntTitleModalMessage('Limite de Autenticação');
              setIntTextModalMessage(
                'Atingido limite mensal de autenticações para um cliente. Por favor, contate seu provedor.',
              );
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            } else if (resp.data.situacaoEnvio === 'LimiteTelefone') {
              setIntTitleModalMessage('Limite de Autenticação');
              setIntTextModalMessage(
                'Atingido limite de autenticações para um telefone. Por favor, contate seu provedor',
              );
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            } else if (resp.data.situacaoEnvio === 'TelefoneInvalido') {
              setIntTitleModalMessage('Telefone Inválido');
              setIntTextModalMessage(
                'O telefone celular cadastrado é inválido. Envio de código de autenticação via SMS impossibilitado. Favor contatar seu provedor.',
              );
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            }
          }
        } else {
          if (
            resp.data.situacaoLiberacao === 'ProcessoLiberado' ||
            resp.data.situacaoLiberacao === 'IspLiberada' ||
            resp.data.situacaoLiberacao === 'ClienteAutenticado'
          ) {
            setIntTitleModalMessage('Reinicie o procedimento');
            setIntTextModalMessage('Situação alterada por outro processo. Por favor, reinicie o procedimento.');
            setModalMessageOpen(true);
            setModalAutenticacaoOpen(false);
            return;
          } else if (resp.data.situacaoLiberacao === 'AutenticacaoExigida') {
            if (resp.data.situacaoEnvio === 'Desnecessario') {
              showAlertApi(resp.statusText, 'error', resp.status);
              return;
            } else if (resp.data.situacaoEnvio !== 'Concorrente') {
              setIntTitleModalMessage('Reinicie o procedimento');
              setIntTextModalMessage('Situação alterada por outro processo. Por favor, reinicie o procedimento.');
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            } else {
              if (resp.data.paAdfOperacao === 'Reenviar') {
                setBodyTextContent(
                  'Um código de autenticação foi reenviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    '. Por favor digite esse código no campo abaixo.',
                );
              } else {
                setBodyTextContent(
                  'Um novo código de autenticação foi enviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    '. Por favor digite esse código no campo abaixo.',
                );
              }
              //debugger
              if (resp.data.restanteEnvio !== undefined) {
                if (resp.data.restanteEnvio !== 1) {
                  setAttentionMessage('Restam ' + resp.data.restanteEnvio + ' envios de autenticação este mês.');
                } else if (resp.data.restanteEnvio === 1) {
                  setAttentionMessage('Resta ' + resp.data.restanteEnvio + ' envio de autenticação este mês.');
                }
              } else {
                setAttentionMessage('');
              }

              if (resp.data.situacaoReenvio === 'Nenhum' || resp.data.situacaoReenvio === 'Antigo') {
                setResponseReenvio('reenviar código');
              } else if (resp.data.situacaoReenvio === 'Novo') {
                setResponseReenvio('enviar novo código');
              }

              setModalAutenticacaoOpen(true);
            }
          }
        }
      }
    });
    return '';
  };

  const handleAut2 = (codigo: string) => {
    // debugger;
    if (codigo !== '') {
      aut2FatService.autAplicarCodigo(codigo).then((resp) => {
        if (resp.data.situacaoAplicacao === 'Falha') {
          showAlertApi('Erro', 'error', resp.status);
          return;
        } else if (resp.data.situacaoAplicacao === 'Liberado') {
          
            setModalConfirmacaoOpen(false);

            setModalAutenticacaoOpen(false);

        } else if (resp.data.situacaoAplicacao === 'CodigoInvalido') {
          showAlertApi('Codigo inválido', 'warning', 5000);
          return;
        } else if (resp.data.situacaoAplicacao === 'CodigoVencido') {
          showAlertApi('Codigo vencido', 'warning', 5000);
          return;
        }
      });
    }
    return '';
  };

  const buscarDescricaoProdutoSelecionado = async function (produto: IProduto) {
    setProdutoSelecionadoModalInfo(produto);

    const codProduto = produto.codigo.toString().padStart(8, '0');
    const xhttp = new XMLHttpRequest();
    xhttp.open('GET', `./content/produto/descricao/prodin_info_${codProduto}.json`, true);

    await xhttp.send();

    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        try {
          const data: any = JSON.parse(xhttp.responseText);

          setProdutoSelecionadoUrlModalInfo(data.url);
          setProdutoSelecionadoDescricaoModalInfo(data.text);
        } catch {
          setProdutoSelecionadoDescricaoModalInfo('Sem descrição');
          setProdutoSelecionadoUrlModalInfo('');
        } finally {
          setModalInfoProdutoOpen(true);
        }
      }
    };
  };

  const handleOpenApp = function (produto: IProduto) {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);

    if (/Android/i.test(userAgent) && produto.urlAndroid) {
      window.open(produto.urlAndroid, '_blank');
    } else if (isIOS && produto.urlIOS) {
      window.open(produto.urlIOS, '_blank');
    } else {
      window.open(produto.urlProduto, '_blank');
    }
  };

  // Executa scripts personalizados por ISP
  useEffect(() => {
    if (
      process.env.REACT_APP_CODIGO_ISP_WEBBY?.length !== 0 &&
      user?.isp?.codigoIsp === process.env.REACT_APP_CODIGO_ISP_WEBBY
    ) {
      const script = document.createElement('script');
      script.src = '../scripts/webbyChat.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const handleOpenModalOptionals = (optionalsType: string) => {
    const found = optionalsFetchData.find((item) => item.type === optionalsType);
    if (found) {
      setModalOptionalsData(found);
    }
  };

  const handleCancelModalOptionals = () => {
    setModalOptionalsData({ type: '', data: [] });
  };

  const handleSaveModalOptionals = (type: string, choiceId: string, chosenName: string) => {
    switch (type) {
      case 'NbaTeamChoice':
        sNba.atualizaEquipeEscolhida(choiceId).then((resp) => {
          if (resp.status === 200) {
            updateOptionalsChoice(type, choiceId, chosenName);
          }
        });
        break;
      default:
        break;
    }
    setModalOptionalsData({ type: '', data: [] });
  };

  const updateOptionalsChoice = (type: string, choiceId: string, chosenName?: string) => {
    const previousSameTypeRemoved = modalOptionalChosen.filter((item) => item.type !== type);
    setModalOptionalChosen([...previousSameTypeRemoved, { type, id: choiceId, name: chosenName }]);
  };

  const fetchListOptionals = (products: Array<IProduto>) => {
    products.forEach((prod: IProduto) => {
      const type = prod.produtoOpcional.tipoProdutoOpcional;
      switch (type) {
        case 'NbaTeamChoice':
          sNba.listaEquipes().then((resp) => {
            if (resp.status === 200) {
              const data = resp.data.map((item: any) => ({ id: item.idNbaEquipe.toString(), nome: item.nomeEquipe }));
              setOptionalsFetchData([...optionalsFetchData, { type: 'NbaTeamChoice', data }]);
              const chosenId = prod.produtoOpcional.opcionais[0]?.idNbaEquipe;
              if (chosenId) {
                updateOptionalsChoice(
                  type,
                  chosenId.toString(),
                  data?.find((item: any) => item.id === chosenId.toString())?.nome,
                );
              }
            }
          });
          break;
        default:
          break;
      }
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // const verifyOptionalSelected = () => {
  //   console.log(produtosNaoElegiveis);
  //   const allProducts = [...produtosNaoElegiveis];
  //   const found = allProducts.find(
  //     (item) => item.selecionado && item.produtoOpcional.tipoProdutoOpcional !== 'SemOpcional',
  //   );
  //   if (found) {
  //     const choice = modalOptionalChosen.find((item) => item.type === found.produtoOpcional.tipoProdutoOpcional);
  //     if (!choice) {
  //       handleOpenModalOptionals(found.produtoOpcional.tipoProdutoOpcional);
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  return (
    <div className="pacote-page">
      <Container className="container">
        <div className="header-container">
          <div className="logout-container">
            <IconButton aria-label="upload picture" component="span" size="small" onClick={() => logout('nba-acesso')}>
              <Typography variant="subtitle2" className="botao-sair" style={{ marginRight: 5 }}>
                SAIR
              </Typography>
              <ExitToAppOutlined className="botao-sair" />
            </IconButton>
          </div>

          <div className="header-content">
            <img
              src={`./content/isp/images/servicos/isp_nba.png`}
              alt="Logo parceiro"
              className="header-image"
            />

            <div className="header-welcome">
              <Typography variant="h6" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} align="left">
                Olá, {user?.nome}
              </Typography>
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className="content-body">
            <Paper elevation={1} className="produto-lista-container">
              <div style={{ marginTop: '0.5rem' }}>
                <AccordionProdutos
                  accordionName="Já no seu pacote"
                  accordionExpanded={"Itens Fixos".toLowerCase().replace(/ /g, '_')}
                  handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                  construirTotalizadores={false}
                  produtos={produtosNaoElegiveis}
                  handleOpenApp={handleOpenApp}
                  buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                  alteracoesHabilitadas={alteracoesHabilitadas}
                  itensFixos={true}
                  handleSelectProduto={handleSelectProduto}
                  handleOptionalModal={handleOpenModalOptionals}
                  chosenOptionals={modalOptionalChosen}
                />
              </div>
            </Paper>
          </div>
        </div>
        <Zoom
            in={isActionButton}
            timeout={300}
          >
          <Fab className='button-primary' variant="round" style={{ position: 'fixed', right: '7vw', bottom: '40px' }} onClick={scrollToTop} >
            <UpIcon />
          </Fab>
        </Zoom>
        <ModalAut2fat
          open={modalAutenticacaoOpen}
          attentionMessage={attentionMessage}
          bodyStandard={modalConfirmacaoInformacoes.bodyStandard}
          bodyPremium={modalConfirmacaoInformacoes.bodyPremium}
          handleClose={() => setModalAutenticacaoOpen(false)}
          handleAut2={(codigo: string) => {
            return handleAut2(codigo);
          }}
          verifyAut2Fat={(operacao: string) => {
            return verifyAut2Fat(operacao);
          }}
          bodyText={bodyTextContent}
          responseReenvio={responseReenvio}
        />

        <ModalMessage
          open={modalMessageOpen}
          headerMessage={intTitleModalMessage}
          bodyMessage={intTextModalMessage}
          handleClose={() => setModalMessageOpen(false)}
        />
        <ModalConfirm
          open={modalConfirmacaoOpen}
          headerMessage={modalConfirmacaoInformacoes.headerText}
          bodyStandard={modalConfirmacaoInformacoes.bodyStandard}
          bodyPremium={modalConfirmacaoInformacoes.bodyPremium}
          handleClose={() => setModalConfirmacaoOpen(false)}
          handleConfirm={() => {
            verifyAut2Fat('Abrir');
          }}
        />

        <ModalInfo
          open={modalInfoProdutoOpen}
          headerMessage={`${produtoSelecionadoModalInfo?.descricao}`}
          bodyMessage={produtoSelecionadoDescricaoModalInfo}
          url={produtoSelecionadoUrlModalInfo}
          handleClose={() => setModalInfoProdutoOpen(false)}
        />

        <ModalOptionals
          open={modalOptionalsData.data.length > 0}
          optionals={modalOptionalsData}
          handleCancel={handleCancelModalOptionals}
          handleSave={handleSaveModalOptionals}
          chosen={modalOptionalChosen}
        />
      </Container>
    </div>
  );
};

export default PartnersPage;

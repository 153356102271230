import {
  Avatar,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  TextField
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  LockOutlined,
  Search,
} from "@material-ui/icons";
import { Color } from "@material-ui/lab";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router";
import MaskedInput from "react-text-mask";
import api from "../api";
import { useUserContext } from "../authentication/userContextProvider";
import { ModalClienteOptionals } from "../components/modal";
import { ValidaTelefoneAuthModal } from "../components/validaTelefoneAuth";
import Message from "../config/message";
import { useAvatarIsp } from "../hooks/useAvatarIsp";
import { usePickStyle } from "../hooks/usePickStyle";
import { ArpEnvioModeloEnvioEnum } from "../interfaces/ArpEnvioModeloEnvioEnum";
import { AuthenticationData } from "../interfaces/AuthenticationData";
import IOptionalsData, { IOptionalChosen } from "../interfaces/IOptionalsData";
import { TipoAutenticacaoEnum } from "../interfaces/TipoAutenticacaoEnum";
import ICliente from "../interfaces/cliente";
import IIsp from "../interfaces/isp";
import IPage from "../interfaces/page";
import IRouteParamsLogin from "../interfaces/routeParamsLogin";
import { useMessage } from "../messages/context";
import clienteService from "../services/clienteService";
import sIsp from "../services/ispService";

const LoginPage: React.FunctionComponent<IPage> = function () {
  const { user, setUser = () => {}, login } = useUserContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [ispSelect, setIspSelect] = useState<IIsp>();
  const [ispNBA, setIspNBA] = useState<IIsp | any>();
  const [searchText, setSearchText] = useState("");
  const { showAlertApp = () => {}, showAlertApi = () => {} } = useMessage();
  const { isAvatar } = useAvatarIsp();
  const paperContainerStyle = usePickStyle({ id: "paper-container" });

  //#region Arp (Autenticação)
  const [tipoAutenticacao, setTipoAutenticacao] =
    useState<TipoAutenticacaoEnum>();
  const [telefoneEhValido, setTelefoneValido] = useState<boolean>(false);
  const [arpEnvioModeloEnvio, setArpEnvioModeloEnvio] =
    useState<ArpEnvioModeloEnvioEnum>();
  const [telefone, setTelefone] = useState("");

  //Referencias ao Modal para validar o telefone do usuario
  const [openValidaTelefone, setOpenValidaTelefone] = useState<boolean>(false);
  const [idCliente, setIdCliente] = useState<number>(0);

  //#endregion

  const [age, setAge] = React.useState("");

  const captcha = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  let param = useParams<IRouteParamsLogin>();
  let grecaptcha: any | undefined = captcha;

  function TextMaskCustom(props: any) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          "(",
          /[1-9]/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={"\u2000"}
        showMask
      />
    );
  }

  const displayedOptionsIspNBA = useMemo(
    () =>
      ispNBA?.filter(
        (option: IIsp) =>
        option.nomeFantasia.toLowerCase().includes(searchText.toLowerCase())
      ),
    [searchText, setIspNBA]
  );

  const handleNBA = function (value: string) {
    var isp = ispNBA.find((obj: IIsp) => obj.codigo === value);
    setIspSelect(isp);
  };

  useEffect(() => {
    setUser(undefined);
    localStorage.clear();
    api.defaults.headers.Authorization = undefined;
  }, [setUser]);

  // Obtém informações do ISP
  useEffect(() => {
    async function getIsp() {
      let codigoIsp = localStorage.getItem("codigoIsp");
      let codigoUrl = localStorage.getItem("codigoUrl");
      let nomeFantasia = localStorage.getItem("nomeFantasiaIsp");
      let tipoAutenticacaoPortalAssinante = localStorage.getItem(
        "tipoAutenticacaoPortalAssinante"
      );

      if (!codigoIsp && !codigoUrl) {
        if (param.isp_code === "nba-acesso") {
          const resp = await sIsp.getIspByCodigoUrl(param.isp_code);
          if (resp) {
            setIspNBA(resp.data);
          }
        } else {
          const resp = await sIsp.getIspByCodigoUrl(param.isp_code);
          codigoIsp = resp.data.codigo;
          codigoUrl = resp.data.codigoUrl;
          nomeFantasia = resp.data.nomeFantasia;
          tipoAutenticacaoPortalAssinante =
            resp.data.tipoAutenticacaoPortalAssinante;
          if (tipoAutenticacaoPortalAssinante === "Usuario") {
            setTipoAutenticacao(TipoAutenticacaoEnum.Usuario);
          } else {
            setTipoAutenticacao(TipoAutenticacaoEnum.Telefone);
          }
        }
      }
      return {
        codigo: codigoIsp,
        codigoUrl,
        nomeFantasia,
        tipoAutenticacaoPortalAssinante,
      };
    }

    getIsp().then((isp) => {
      setUser((prevState: any) => {
        return {
          ...prevState,
          isp,
        };
      });
    });
  }, [param.isp_code, setUser, ispSelect]);

  const onReCaptchaChange = (value: any) => {
    setRecaptchaToken(value);
  };

  // Faz o login atualizando o contexto do usuário e fazendo os redirecionamentos necessários.
  const submitLogin = async function () {
    const authenticationData = new AuthenticationData(
      recaptchaToken,
      TipoAutenticacaoEnum.Usuario
    );
    if (param.isp_code === "nba-acesso") {
      login(
        username,
        password,
        param.isp_code,
        authenticationData,
        ispSelect?.codigo,
        true
      );
    } else {
      login(
        username,
        password,
        user?.isp?.codigoUrl,
        authenticationData,
        user?.isp?.codigo
      );
    }
  };

  const handleValidaTelefone = function (value: string) {
    if (tipoAutenticacao === TipoAutenticacaoEnum.Usuario) {
      setTelefoneValido(false);
    } else if (value?.trim()[2] !== "9") {
      setTelefoneValido(false);
    } else {
      setTelefoneValido(true);
    }
  };

  const phoneMask = (phone: string) => {
    const inputValue = phone.replace(/\D/g, "");

    let formattedPhoneNumber = "";

    if (inputValue.length <= 2) {
      formattedPhoneNumber = `(${inputValue}`;
    } else if (inputValue.length <= 7) {
      formattedPhoneNumber = `(${inputValue.slice(0, 2)})${inputValue.slice(
        2
      )}`;
    } else {
      formattedPhoneNumber = `(${inputValue.slice(0, 2)})${inputValue.slice(
        2,
        7
      )}-${inputValue.slice(7, 11)}`;
    }
    setTelefone(formattedPhoneNumber);
    return inputValue;
  };

  const handleCarregaClientePorTelefone = async function () {
    if (tipoAutenticacao !== TipoAutenticacaoEnum.Telefone) {
      showAlertApi("Tipo de autenticação incorreta.", "warning", 3000);
      return;
    }

    if (!telefoneEhValido) {
      showAlertApi("O telefone deve ser válido.", "warning", 3000);
      return;
    }

    if (arpEnvioModeloEnvio === null) {
      showAlertApi("Modelo de envio não configurado.", "warning", 3000);
      return;
    }

    const resp = await clienteService
      .listClientesTelefone(
        user?.isp?.codigo + "",
        phoneMask(telefone),
        recaptchaToken
      )
      .then((resp: { status: number; data: any[] }) => {
        if (resp.status !== 200) {
          showAlertApi("Erro no envio dos dados.", "error", 3000);
          return;
        }
        if (resp.data?.length === 1) {
          const data = resp.data.map(() => ({
            login: resp.data[0].login,
            nome: resp.data[0].nome,
            idCliente: resp.data[0].idCliente,
          }));
          setOpenValidaTelefone(true);
          setIdCliente(data[0].idCliente);
        } else if (resp.data?.length > 1) {
          const data = resp.data.map((item: ICliente) => ({
            nome: item.nome,
            login: item.login,
            idCliente: item.idCliente,
          }));
          setOptionalsFetchData([
            ...optionalsFetchData,
            { type: "ArpCLiente", data },
          ]);
          setOpenOptionsModal(true);
        } else {
          showAlertApi("Modelo de envio não configurado.", "warning", 3000);
        }
      })
      .finally(() => {
        setRecaptchaToken("");
        grecaptcha.current.reset();
      });
  };

  const [optionalsFetchData, setOptionalsFetchData] = useState<
    Array<IOptionalsData>
  >([]);
  const [modalOptionalChosen, setModalOptionalChosen] = useState<
    Array<IOptionalChosen>
  >([]);
  const [modalOptionalsData, setModalOptionalsData] = useState<IOptionalsData>({
    type: "",
    data: [],
  });
  const [openOptionsModal, setOpenOptionsModal] = useState<boolean>(false);

  const handleCancelModalOptionals = () => {
    setModalOptionalsData({ type: "", data: [] });
    setOpenOptionsModal(false);
  };

  const handleSaveModalOptionals = (
    type: string,
    choiceId: string,
    chosenName: string
  ) => {
    switch (type) {
      case "ArpCLiente":
        updateOptionalsChoice(type, choiceId, chosenName);
        setIdCliente(Number(choiceId));
        setOpenValidaTelefone(true);
        break;
      default:
        break;
    }
    setModalOptionalsData({ type: "", data: [] });
  };

  const updateOptionalsChoice = (
    type: string,
    choiceId: string,
    chosenName?: string
  ) => {
    const previousSameTypeRemoved = modalOptionalChosen.filter(
      (item) => item.type !== type
    );
    setModalOptionalChosen([
      ...previousSameTypeRemoved,
      { type, id: choiceId, name: chosenName },
    ]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpenModalOptionals = (optionalsType: string) => {
    const found = optionalsFetchData.find(
      (item) => item.type === optionalsType
    );
    if (found) {
      setModalOptionalsData(found);
    }
  };

  const handleCloseValidaTelefone = () => {
    setOpenValidaTelefone(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    handleOpenModalOptionals("ArpCLiente");
  }, [handleOpenModalOptionals]);

  if (
    tipoAutenticacao === TipoAutenticacaoEnum.Usuario ||
    param.isp_code === "nba-acesso"
  ) {
    return (
      <div className="login-page">
        <div className="header-container">
          {param.isp_code === "nba-acesso" ? (
            <Avatar
              src={`./content/isp/images/login/isp_nba.png`}
              className="avatar"
              variant="rounded"
            />
          ) : (
            <Avatar
              src={`./content/isp/images/login/isp_${user?.isp?.codigo}.png`}
              className="avatar"
              variant="rounded"
            />
          )}
        </div>
        <Container component="main" maxWidth="xs" className="form-container">
          <div className="avatar-container-login-new-model"></div>
          <div className="avatar-container-login">
            <Paper className="paper-container" elevation={2}>
              <form className="form" autoComplete="new-password">
                {param.isp_code === "nba-acesso" ? (
                  <>
                    <FormControl fullWidth>
                      <TextField
                        select
                        id="search-select"
                        variant="outlined"
                        required
                        value={ispSelect?.nomeFantasia}
                        label={"Provedor"}
                        onChange={(event: any) => {
                          handleNBA(event?.target.value);
                        }}
                        onAnimationEnd={() => setSearchText("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            placeholder="Pesquisar..."
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchText.length < 3 && (
                          <InputLabel
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              marginBottom: "5px",
                            }}
                            className="searchText"
                          >
                            Digite 3 ou mais caracteres para pesquisar
                          </InputLabel>
                        )}
                        {searchText.length >= 3 &&
                          displayedOptionsIspNBA?.length === 0 && (
                            <InputLabel
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              Nenhum resultado
                            </InputLabel>
                          )}
                        {searchText.length >= 3 &&
                          displayedOptionsIspNBA != undefined &&
                          displayedOptionsIspNBA?.map((item: IIsp) => (
                            <MenuItem key={item.codigo} value={item.codigo}>
                              {item.nomeFantasia}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </>
                ) : (
                  ""
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={Message.usuario}
                  name="usuario"
                  inputProps={{ maxLength: 60 }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleOutlined color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={Message.senha}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  inputProps={{ maxLength: 60 }}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockOutlined color="action" />
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  fullWidth
                  size="large"
                  className="button-primary"
                  onClick={submitLogin}
                >
                  {Message.acessar}
                </Button>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    paddingTop: "20px",
                  }}
                >
                  {/* {user?.isp?.tipoAutenticacaoPortalAssinante !== "Usuario" ? (
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      size="small"
                      className="button-primary"
                      onClick={() => {
                        if (tipoAutenticacao === TipoAutenticacaoEnum.Usuario) {
                          setTipoAutenticacao(TipoAutenticacaoEnum.Telefone);
                        } else {
                          setTipoAutenticacao(TipoAutenticacaoEnum.Usuario);
                        }
                      }}
                    >
                      {Message.loginTelefone}
                    </Button>
                  ) : (
                    <></>
                  )} */}
                </div>
              </form>
            </Paper>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY + ""}
              hl="pt"
              onChange={onReCaptchaChange}
              ref={captcha}
            />
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="login-page">
        <div className="header-container">
          {param.isp_code === "nba-acesso" ? (
            <Avatar
              src={`./content/isp/images/login/isp_nba.png`}
              className="avatar"
              variant="rounded"
            />
          ) : (
            <Avatar
              src={`./content/isp/images/login/isp_${user?.isp?.codigo}.png`}
              className="avatar"
              variant="rounded"
            />
          )}
          <p
            style={{
              marginTop: isAvatar ? "40px" : "20px",
              color: paperContainerStyle?.backgroundColor,
            }}
          >
            Bem-vindo ao Portal do Assinante<br />{user?.isp?.nomeFantasia}
          </p>
          <span 
            style={{ color: paperContainerStyle?.backgroundColor }}
          >
            Escolha seus aplicativos em <br /> poucos cliques
          </span>
        </div>
        <Container
          component="main"
          maxWidth="xs"
          className="form-container"

        >
          <div id="login-avatar-external" style={{ height: isAvatar.external ? "200px" : "" }} className="avatar-container-login-new-model" />
          <div id="login-avatar-internal" className={`${isAvatar.internal ? "avatar-container-login" : ""}`}>
            <Paper id="paper-container" className="paper-container" elevation={2}>
              <form
                className="form"
                autoComplete="new-password"
                onKeyDown={handleKeyDown}
              >
                {param.isp_code === "nba-acesso" ? (
                  <>
                    <FormControl fullWidth>
                      <TextField
                        select
                        id="search-select"
                        variant="outlined"
                        required
                        value={ispSelect?.nomeFantasia}
                        label={"Provedor"}
                        onChange={(event: any) => {
                          handleNBA(event?.target.value);
                        }}
                        onAnimationEnd={() => setSearchText("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            placeholder="Pesquisar..."
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchText.length < 3 && (
                          <InputLabel
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              marginBottom: "5px",
                            }}
                            className="searchText"
                          >
                            Digite 3 ou mais caracteres para pesquisar
                          </InputLabel>
                        )}
                        {searchText.length >= 3 &&
                          displayedOptionsIspNBA?.length === 0 && (
                            <InputLabel
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              Nenhum resultado
                            </InputLabel>
                          )}
                        {searchText.length >= 3 &&
                          displayedOptionsIspNBA != undefined &&
                          displayedOptionsIspNBA?.map((item: IIsp) => (
                            <MenuItem key={item.codigo} value={item.codigo}>
                              {item.nomeFantasia}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </>
                ) : (
                  ""
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    paddingTop: "10px",
                    width: "100%",
                  }}
                >
                  <FormLabel className="labelInput">
                    {" "}
                    Digite o número do telefone celular registrado{" "}
                  </FormLabel>
                </div>
                <TextField
                  id="textTelefone"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  label={Message.telefone}
                  name="telefone"
                  inputProps={{ maxLength: 60 }}
                  value={telefone}
                  onChange={(e) => {
                    const unmaskedPhone = phoneMask(e.target.value);
                    handleValidaTelefone(unmaskedPhone);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    paddingTop: "40px",
                    width: "100%",
                  }}
                >
                  <FormLabel className="optionalLogin-text">
                    {" "}
                    Enviar código de verificação por:{" "}
                  </FormLabel>
                </div>

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      size="large"
                      className={"button-primary"}
                      disabled={!telefoneEhValido}
                      onClick={() => {
                        setArpEnvioModeloEnvio(ArpEnvioModeloEnvioEnum.Sms);
                        handleCarregaClientePorTelefone();
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        opacity: !telefoneEhValido ? 0.6 : undefined,
                      }}
                    >
                      {Message.sms}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      size="large"
                      className={"button-primary"}
                      disabled={!telefoneEhValido}
                      onClick={() => {
                        setArpEnvioModeloEnvio(
                          ArpEnvioModeloEnvioEnum.Whatsapp
                        );
                        handleCarregaClientePorTelefone();
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        opacity: !telefoneEhValido ? 0.6 : undefined,
                      }}
                    >
                      {Message.whatsapp}
                    </Button>
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    paddingTop: "30px",
                  }}
                >
                  {/* <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    size="small"
                    className="button-primary"
                    onClick={() => {
                      if (tipoAutenticacao === TipoAutenticacaoEnum.Telefone) {
                        setTipoAutenticacao(TipoAutenticacaoEnum.Usuario);
                      } else {
                        setTipoAutenticacao(TipoAutenticacaoEnum.Telefone);
                      }
                    }}
                  >
                    {Message.loginUsuario}
                  </Button> */}
                  <ModalClienteOptionals
                    open={openOptionsModal}
                    optionals={modalOptionalsData}
                    handleCancel={handleCancelModalOptionals}
                    handleSave={handleSaveModalOptionals}
                    chosen={modalOptionalChosen}
                    textTrueButton={"Selecionar"}
                  />
                  <ValidaTelefoneAuthModal
                    open={openValidaTelefone}
                    handleClose={handleCloseValidaTelefone}
                    arpEnvio={arpEnvioModeloEnvio}
                    idCliente={idCliente}
                    captcha={recaptchaToken}
                  />
                </div>
              </form>
            </Paper>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY + ""}
              hl="pt"
              onChange={onReCaptchaChange}
              ref={captcha}
            />
          </div>
        </Container>
      </div>
    );
  }
};

export default LoginPage;

import { Button, Container, Fab, IconButton, Menu, MenuItem, Paper, Tooltip, Typography, Zoom } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import SaveIcon from '@material-ui/icons/Save';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../api';
import { useUserContext } from '../authentication/userContextProvider';
import AccordionProdutos from '../components/accordionProdutos';
import ModalConfirm, { ModalInfo, ModalOptionals } from '../components/modal';
import ModalAut2fat, { ModalMessage } from '../components/modalAut2fat/index';
import { NameProdutoEnum, TextoExplicativoProprio } from '../config/enum';
import Message from '../config/message';
import { usePickStyle } from '../hooks/usePickStyle';
import IInformacoesQuantidadeProdutos, { InitialValues } from '../interfaces/IInformacoesQuantidadeProdutos';
import IOptionalsData, { IOptionalChosen } from '../interfaces/IOptionalsData';
import IPage from '../interfaces/page';
import IProduto from '../interfaces/produto';
import { useMessage } from '../messages/context';
import aut2FatService from '../services/aut2FatService';
import sNba from '../services/nbaService';
import sProdutos from '../services/produtoService';
import { invertRgbColor } from '../helpers/invertRgbColor';

const getProdutoName = (index: number): string => {
  return NameProdutoEnum[Object.keys(NameProdutoEnum)[index] as keyof typeof NameProdutoEnum];
}

const PartnersPage: React.FunctionComponent<IPage> = function (props) {
  const colorContrast = usePickStyle({
    id: "pick-container",
    styleProperty: "background-color"
  });
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false | undefined>(undefined);
  const [isActionButton, setIsActionButton] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChangeAccordionExpanded =
    (panel: string) => (event: React.ChangeEvent<{}>, newAccordionExpanded: boolean) => {
      setAccordionExpanded(newAccordionExpanded ? panel : false);
    };

  const { user, logout = () => {} } = useUserContext();
  const { showAlertApp = () => {}, showAlertApi = () => {} } = useMessage();

  const [produtosNaoElegiveis, setProdutosNaoElegiveis] = useState<IProduto[]>([]);
  const [produtosPremium, setProdutosPremium] = useState<IProduto[]>([]);
  const [produtosStandard, setProdutosStandard] = useState<IProduto[]>([]);
  const [produtosNivel004, setProdutosNivel004] = useState<IProduto[]>([]);
  const [produtosNivel005, setProdutosNivel005] = useState<IProduto[]>([]);
  const [produtosNivel006, setProdutosNivel006] = useState<IProduto[]>([]);
  const [produtosNivel007, setProdutosNivel007] = useState<IProduto[]>([]);

  const [informacoesQuantidadeStandard, setInformacoesQuantidadeStandard] =
    useState<IInformacoesQuantidadeProdutos>(InitialValues);
  const [informacoesQuantidadePremium, setInformacoesQuantidadePremium] =
    useState<IInformacoesQuantidadeProdutos>(InitialValues);
    const [informacoesQuantidadeNivel004, setInformacoesQuantidadeNivel004] =
    useState<IInformacoesQuantidadeProdutos>(InitialValues);
    const [informacoesQuantidadeNivel005, setInformacoesQuantidadeNivel005] =
    useState<IInformacoesQuantidadeProdutos>(InitialValues);
    const [informacoesQuantidadeNivel006, setInformacoesQuantidadeNivel006] =
    useState<IInformacoesQuantidadeProdutos>(InitialValues);
    const [informacoesQuantidadeNivel007, setInformacoesQuantidadeNivel007] =
    useState<IInformacoesQuantidadeProdutos>(InitialValues);

  const [houveAlteracao, setHouveAlteracao] = useState<boolean>(false);
  const [alteracoesHabilitadas, setAlteracoesHabilitadas] = useState<boolean>(true);

  const [modalConfirmacaoOpen, setModalConfirmacaoOpen] = useState<boolean>(false);
  const [modalConfirmacaoInformacoes, setModalConfirmacaoInformacoes] = useState({
    headerText: '',
    bodyStandard: [],
    bodyPremium: [],
    bodyNivel004: [],
    bodyNivel005: [],
    bodyNivel006: [],
    bodyNivel007: [],
  });

  const [modalInfoProdutoOpen, setModalInfoProdutoOpen] = useState<boolean>(false);
  const [produtoSelecionadoModalInfo, setProdutoSelecionadoModalInfo] = useState<IProduto | null>(null);
  const [produtoSelecionadoDescricaoModalInfo, setProdutoSelecionadoDescricaoModalInfo] = useState<string>('');
  const [produtoSelecionadoUrlModalInfo, setProdutoSelecionadoUrlModalInfo] = useState<string>('');

  const [modalMessageOpen, setModalMessageOpen] = useState<boolean>(false);
  const [intTextModalMessage, setIntTextModalMessage] = useState<string>('');
  const [intTitleModalMessage, setIntTitleModalMessage] = useState<string>('');
  const [attentionMessage, setAttentionMessage] = useState<string>('');
  const [bodyTextContent, setBodyTextContent] = useState<string>('');
  const [modalAutenticacaoOpen, setModalAutenticacaoOpen] = useState<boolean>(false);
  const [responseReenvio, setResponseReenvio] = useState<string>('');
  const [optionalsFetchData, setOptionalsFetchData] = useState<Array<IOptionalsData>>([]);
  const [modalOptionalChosen, setModalOptionalChosen] = useState<Array<IOptionalChosen>>([]);
  const [modalOptionalsData, setModalOptionalsData] = useState<IOptionalsData>({ type: '', data: [] });

  const refreshProdutos = useCallback(() => {
    sProdutos.listaProdutos().then((resp) => {
      const {
        itensNaoElegiveis,
        itensStandard,
        itensPremium,
        itensNivel004,
        itensNivel005,
        itensNivel006,
        itensNivel007,
        totalPermitidoStandard,
        totalPermitidoPremium,
        totalPermitidoNivel004,
        totalPermitidoNivel005,
        totalPermitidoNivel006,
        totalPermitidoNivel007,
        habilitado,
        mensagem,
        mensagemConfirmacao,
      } = resp.data;

      function verifyName(items: Omit<IProduto, 'nameVerify'>[]): IProduto[] {
        return items.map((item) => {
          let name;
  
          if(item.produtoIspExtraPaDescricao) {
            name = item.produtoIspExtraPaDescricao;
          } else  {
            name = item.descricao;
          }
          
          return {...item, nameVerify: name || ""};
        })
      }

      const prodNaoElegiveis = verifyName(itensNaoElegiveis);
      const prodStandard = verifyName(itensStandard);
      const prodPremium = verifyName(itensPremium);
      const prodNivel004 = verifyName(itensNivel004);
      const prodNivel005 = verifyName(itensNivel005);
      const prodNivel006 = verifyName(itensNivel006);
      const prodNivel007 = verifyName(itensNivel007);

      setProdutosNaoElegiveis(prodNaoElegiveis);
      setProdutosStandard(prodStandard);
      setProdutosPremium(prodPremium);
      setProdutosNivel004(prodNivel004);
      setProdutosNivel005(prodNivel005);
      setProdutosNivel006(prodNivel006);
      setProdutosNivel007(prodNivel007);

      const allProducts = [...prodNaoElegiveis, ...prodStandard, ...prodPremium, ...prodNivel004, ...prodNivel005, ...prodNivel006, ...prodNivel007];
      fetchListOptionals(allProducts);

      const selecionadosStandard = itensStandard.filter((prod: IProduto) => prod.selecionado).length;
      const selecionadosPremium = itensPremium.filter((prod: IProduto) => prod.selecionado).length;
      const selecionadosNivel004 = itensNivel004.filter((prod: IProduto) => prod.selecionado).length;
      const selecionadosNivel005 = itensNivel005.filter((prod: IProduto) => prod.selecionado).length;
      const selecionadosNivel006 = itensNivel006.filter((prod: IProduto) => prod.selecionado).length;
      const selecionadosNivel007 = itensNivel007.filter((prod: IProduto) => prod.selecionado).length;

      setInformacoesQuantidadeStandard({
        totalPermitido: totalPermitidoStandard,
        disponivel: totalPermitidoStandard - selecionadosStandard,
        selecionado: selecionadosStandard,
      });

      setInformacoesQuantidadePremium({
        totalPermitido: totalPermitidoPremium,
        disponivel: totalPermitidoPremium - selecionadosPremium,
        selecionado: selecionadosPremium,
      });

      setInformacoesQuantidadeNivel004({
        totalPermitido: totalPermitidoNivel004,
        disponivel: totalPermitidoNivel004 - selecionadosNivel004,
        selecionado: selecionadosNivel004,
      });

      setInformacoesQuantidadeNivel005({
        totalPermitido: totalPermitidoNivel005,
        disponivel: totalPermitidoNivel005 - selecionadosNivel005,
        selecionado: selecionadosNivel005,
      });

      setInformacoesQuantidadeNivel006({
        totalPermitido: totalPermitidoNivel006,
        disponivel: totalPermitidoNivel006 - selecionadosNivel006,
        selecionado: selecionadosNivel006,
      });

      setInformacoesQuantidadeNivel007({
        totalPermitido: totalPermitidoNivel007,
        disponivel: totalPermitidoNivel007 - selecionadosNivel007,
        selecionado: selecionadosNivel007,
      });

      setAlteracoesHabilitadas(habilitado);
      showAlertApi(mensagem, 'info', 10_000);

      setModalConfirmacaoInformacoes({
        ...modalConfirmacaoInformacoes,
        headerText: mensagemConfirmacao,
      });
    });
  }, []);

  useEffect(() => {
    if(api.defaults.headers.Authorization !== undefined) {
      refreshProdutos();
    }
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsActionButton(true);
    } else {
      setIsActionButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const formatDate = (input: any) => {
    const datePart = input?.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2],
      hora = datePart[3],
      min = datePart[4];
    return day + '/' + month + '/' + year + ' às ' + hora + ':' + min;
  };
  const setInformacoesQuantidadeSelecionadosDisponiveis = useCallback(() => {
    const produtosSelecionadosStandard = produtosStandard.filter((prod) => prod.selecionado).length;
    const produtosSelecionadosPremium = produtosPremium.filter((prod) => prod.selecionado).length;
    const produtosSelecionadosNivel004 = produtosNivel004.filter((prod) => prod.selecionado).length;
    const produtosSelecionadosNivel005 = produtosNivel005.filter((prod) => prod.selecionado).length;
    const produtosSelecionadosNivel006 = produtosNivel006.filter((prod) => prod.selecionado).length;
    const produtosSelecionadosNivel007 = produtosNivel007.filter((prod) => prod.selecionado).length;

    setInformacoesQuantidadeStandard({
      totalPermitido: informacoesQuantidadeStandard.totalPermitido,
      selecionado: produtosSelecionadosStandard,
      disponivel: informacoesQuantidadeStandard.totalPermitido - produtosSelecionadosStandard,
    });

    setInformacoesQuantidadePremium({
      totalPermitido: informacoesQuantidadePremium.totalPermitido,
      selecionado: produtosSelecionadosPremium,
      disponivel: informacoesQuantidadePremium.totalPermitido - produtosSelecionadosPremium,
    });

    setInformacoesQuantidadeNivel004({
      totalPermitido: informacoesQuantidadeNivel004.totalPermitido,
      selecionado: produtosSelecionadosNivel004,
      disponivel: informacoesQuantidadeNivel004.totalPermitido - produtosSelecionadosNivel004,
    });

    setInformacoesQuantidadeNivel005({
      totalPermitido: informacoesQuantidadeNivel005.totalPermitido,
      selecionado: produtosSelecionadosNivel005,
      disponivel: informacoesQuantidadeNivel005.totalPermitido - produtosSelecionadosNivel005,
    });

    setInformacoesQuantidadeNivel006({
      totalPermitido: informacoesQuantidadeNivel006.totalPermitido,
      selecionado: produtosSelecionadosNivel006,
      disponivel: informacoesQuantidadeNivel006.totalPermitido - produtosSelecionadosNivel006,
    });

    setInformacoesQuantidadeNivel007({
      totalPermitido: informacoesQuantidadeNivel007.totalPermitido,
      selecionado: produtosSelecionadosNivel007,
      disponivel: informacoesQuantidadeNivel007.totalPermitido - produtosSelecionadosNivel007,});
  }, [informacoesQuantidadeStandard, informacoesQuantidadePremium, informacoesQuantidadeNivel004, informacoesQuantidadeNivel005, informacoesQuantidadeNivel006, informacoesQuantidadeNivel007]);

  const setInformacoesModalConfirmacao = useCallback(() => {
    const produtosSelecionadosStandard = produtosStandard.filter((prod) => prod.selecionado) as never[];
    const produtosSelecionadosPremium = produtosPremium.filter((prod) => prod.selecionado) as never[];
    const produtosSelecionadosNivel004 = produtosNivel004.filter((prod) => prod.selecionado) as never[];
    const produtosSelecionadosNivel005 = produtosNivel005.filter((prod) => prod.selecionado) as never[];
    const produtosSelecionadosNivel006 = produtosNivel006.filter((prod) => prod.selecionado) as never[];
    const produtosSelecionadosNivel007 = produtosNivel007.filter((prod) => prod.selecionado) as never[];

    setModalConfirmacaoInformacoes({
      ...modalConfirmacaoInformacoes,
      bodyStandard: produtosSelecionadosStandard,
      bodyPremium: produtosSelecionadosPremium,
      bodyNivel004: produtosSelecionadosNivel004,
      bodyNivel005: produtosSelecionadosNivel005,
      bodyNivel006: produtosSelecionadosNivel006,
      bodyNivel007: produtosSelecionadosNivel007,
    });
  }, [produtosStandard, produtosPremium, produtosNivel004, produtosNivel005, produtosNivel006, produtosNivel007]);

  const handleSelectProduto = (
    e: React.ChangeEvent<HTMLInputElement>,
    produtoCategoriaDisponivel: number,
    setStateProduto: (prevState: any) => void,
    codigoSVA: string,
    warning: boolean,
  ) => {
    if(warning) showAlertApp(Message.excedeuQuantidadePermitidaGeneric, "warning", 4000);

    setStateProduto((prevState: any) => {
      const updatedSva = [...prevState];
      const index = updatedSva.findIndex((prod) => prod.descricao === codigoSVA);
      const produto = updatedSva[index];

      produto.selecionado = e.target.checked;
      produto.alterado = produto.alterado == null || produto.alterado == false;

      return updatedSva;
    });

    setInformacoesQuantidadeSelecionadosDisponiveis();
  };

  useEffect(() => {
    // Verifica se houveram alterações
    const todosProdutos = produtosStandard.concat(produtosPremium).concat(produtosNivel004).concat(produtosNivel005).concat(produtosNivel006).concat(produtosNivel007);
    setHouveAlteracao(todosProdutos.filter((prod) => prod.alterado).length > 0);
  }, [produtosStandard, produtosPremium, produtosNivel004, produtosNivel005, produtosNivel006, produtosNivel007]);

  // Considerar que todos standard e premium precisam estar selecionados
  const selecionarPacotes = () => {
    const disponivelStandard = informacoesQuantidadeStandard.disponivel;
    const disponivelPremium = informacoesQuantidadePremium.disponivel;
    const disponivelNivel004 = informacoesQuantidadeNivel004.disponivel;
    const disponivelNivel005 = informacoesQuantidadeNivel005.disponivel;
    const disponivelNivel006 = informacoesQuantidadeNivel006.disponivel;
    const disponivelNivel007 = informacoesQuantidadeNivel007.disponivel;

    if(informacoesQuantidadeStandard.selecionado > informacoesQuantidadeStandard.totalPermitido) return showAlertApp(Message.excedeuQuantidadePermitidaStandard, 'warning', 5000);
    if(informacoesQuantidadePremium.selecionado > informacoesQuantidadePremium.totalPermitido) return showAlertApp(Message.excedeuQuantidadePermitidaPremium, 'warning', 5000);
    if(informacoesQuantidadeNivel004.selecionado > informacoesQuantidadeNivel004.totalPermitido) return showAlertApp(Message.excedeuQuantidadePermitidaNivel004, 'warning', 5000);
    if(informacoesQuantidadeNivel005.selecionado > informacoesQuantidadeNivel005.totalPermitido) return showAlertApp(Message.excedeuQuantidadePermitidaNivel005, 'warning', 5000);
    if(informacoesQuantidadeNivel006.selecionado > informacoesQuantidadeNivel006.totalPermitido) return showAlertApp(Message.excedeuQuantidadePermitidaNivel006, 'warning', 5000);
    if(informacoesQuantidadeNivel007.selecionado > informacoesQuantidadeNivel007.totalPermitido) return showAlertApp(Message.excedeuQuantidadePermitidaNivel007, 'warning', 5000);
    
    if (disponivelStandard == 0 && disponivelPremium == 0 && disponivelNivel004 == 0 && disponivelNivel005 == 0 && disponivelNivel006 == 0 && disponivelNivel007 == 0) {
      if (verifyOptionalSelected()) {
        setInformacoesModalConfirmacao();
        setModalConfirmacaoOpen(true);
      }
    } else {
      let mensagem = '';

      [disponivelStandard, disponivelPremium, disponivelNivel004, disponivelNivel005, disponivelNivel006, disponivelNivel007].forEach((disponivel, index) => {
        if (disponivel != 0) {
          return mensagem = `Você deve selecionar ${disponivel} serviço(s) da categoria ${getProdutoName(index)}`;
        }
      })

      showAlertApp(mensagem, 'warning', 5000);
    }
  };

  const verifyAut2Fat = (operacao: string) => {
    //debugger
    aut2FatService.getCodigoAut2Fat({ PaAdfOperacao: operacao, modeloEnvio: "Sms" }).then((resp) => {
      //debugger
      if (resp.data) {
        // debugger;
        if (resp.data.situacaoLiberacao === 'Falha') {
          showAlertApi(resp.statusText, 'error', resp.status);
          return;
        } else if (resp.data.paAdfOperacao === 'Abrir') {
          if (
            resp.data.situacaoLiberacao === 'ProcessoLiberado' ||
            resp.data.situacaoLiberacao === 'IspLiberada' ||
            resp.data.situacaoLiberacao === 'ClienteAutenticado'
          ) {
            const codigosAtivosStandard = produtosStandard
              .filter((prod) => prod.selecionado)
              .map((prod) => prod.codigo);
            const codigosInativosStandard = produtosStandard
              .filter((prod) => !prod.selecionado)
              .map((prod) => prod.codigo);

            const codigosAtivosPremium = produtosPremium.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
            const codigosInativosPremium = produtosPremium
              .filter((prod) => !prod.selecionado)
              .map((prod) => prod.codigo);

            const codigosAtivosNivel004 = produtosNivel004.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
            const codigosInativosNivel004 = produtosNivel004.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

            const codigosAtivosNivel005 = produtosNivel005.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
            const codigosInativosNivel005 = produtosNivel005.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

            const codigosAtivosNivel006 = produtosNivel006.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
            const codigosInativosNivel006 = produtosNivel006.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

            const codigosAtivosNivel007 = produtosNivel007.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
            const codigosInativosNivel007 = produtosNivel007.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

            setModalConfirmacaoOpen(false);
            sProdutos
              .selecionaProdutos({
                codigosAtivosStandard,
                codigosInativosStandard,
                codigosAtivosPremium,
                codigosInativosPremium,
                codigosAtivosNivel004,
                codigosInativosNivel004,
                codigosAtivosNivel005,
                codigosInativosNivel005,
                codigosAtivosNivel006,
                codigosInativosNivel006,
                codigosAtivosNivel007,
                codigosInativosNivel007,
              })
              .then((resp) => {
                if (resp.data) {
                  showAlertApi(resp.data, 'success', 5000);
                }

                refreshProdutos();
                setHouveAlteracao(false);
                setModalConfirmacaoOpen(false);
                setModalAutenticacaoOpen(false);
              });
          } else if (resp.data.situacaoLiberacao === 'AutenticacaoExigida') {
            if (resp.data.situacaoEnvio === 'Desnecessario') {
              showAlertApi(resp.statusText, 'error', resp.status);
              return;
            } else if (resp.data.situacaoEnvio === 'Concorrente' || resp.data.situacaoEnvio === 'Precedente') {
              //debugger
              if (resp.data.restanteEnvio !== undefined) {
                if (resp.data.restanteEnvio !== 1) {
                  setAttentionMessage('Restam ' + resp.data.restanteEnvio + ' envios de autenticação este mês.');
                } else {
                  setAttentionMessage('Resta ' + resp.data.restanteEnvio + ' envio de autenticação este mês.');
                }
              } else {
                setAttentionMessage('');
              }
              // Texto que apareça no body
              if (resp.data.situacaoEnvio === 'Concorrente') {
                setBodyTextContent(
                  'Um código de autenticação foi enviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    '. Por favor digite esse código no campo abaixo.',
                );
              } else if (resp.data.situacaoEnvio === 'Precedente') {
                setBodyTextContent(
                  'Um código de autenticação foi enviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    ' em ' +
                    formatDate(resp.data.dataEnvio) +
                    '. Por favor digite esse código no campo abaixo.',
                );
              }

              if (resp.data.situacaoReenvio === 'Nenhum' || resp.data.situacaoReenvio === 'Antigo') {
                setResponseReenvio('reenviar código');
              } else if (resp.data.situacaoReenvio === 'Novo') {
                setResponseReenvio('enviar novo código');
              }

              setModalAutenticacaoOpen(true);
            } else if (resp.data.situacaoEnvio === 'LimiteCliente') {
              setIntTitleModalMessage('Limite de Autenticação');
              setIntTextModalMessage(
                'Atingido limite mensal de autenticações para um cliente. Por favor, contate seu provedor.',
              );
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            } else if (resp.data.situacaoEnvio === 'LimiteTelefone') {
              setIntTitleModalMessage('Limite de Autenticação');
              setIntTextModalMessage(
                'Atingido limite de autenticações para um telefone. Por favor, contate seu provedor',
              );
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            } else if (resp.data.situacaoEnvio === 'TelefoneInvalido') {
              setIntTitleModalMessage('Telefone Inválido');
              setIntTextModalMessage(
                'O telefone celular cadastrado é inválido. Envio de código de autenticação via SMS impossibilitado. Favor contatar seu provedor.',
              );
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            }
          }
        } else {
          if (
            resp.data.situacaoLiberacao === 'ProcessoLiberado' ||
            resp.data.situacaoLiberacao === 'IspLiberada' ||
            resp.data.situacaoLiberacao === 'ClienteAutenticado'
          ) {
            setIntTitleModalMessage('Reinicie o procedimento');
            setIntTextModalMessage('Situação alterada por outro processo. Por favor, reinicie o procedimento.');
            setModalMessageOpen(true);
            setModalAutenticacaoOpen(false);
            return;
          } else if (resp.data.situacaoLiberacao === 'AutenticacaoExigida') {
            if (resp.data.situacaoEnvio === 'Desnecessario') {
              showAlertApi(resp.statusText, 'error', resp.status);
              return;
            } else if (resp.data.situacaoEnvio !== 'Concorrente') {
              setIntTitleModalMessage('Reinicie o procedimento');
              setIntTextModalMessage('Situação alterada por outro processo. Por favor, reinicie o procedimento.');
              setModalMessageOpen(true);
              setModalAutenticacaoOpen(false);
              return;
            } else {
              if (resp.data.paAdfOperacao === 'Reenviar') {
                setBodyTextContent(
                  'Um código de autenticação foi reenviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    '. Por favor digite esse código no campo abaixo.',
                );
              } else {
                setBodyTextContent(
                  'Um novo código de autenticação foi enviado via SMS para o telefone ' +
                    resp.data.telefoneCelular +
                    '. Por favor digite esse código no campo abaixo.',
                );
              }
              //debugger
              if (resp.data.restanteEnvio !== undefined) {
                if (resp.data.restanteEnvio !== 1) {
                  setAttentionMessage('Restam ' + resp.data.restanteEnvio + ' envios de autenticação este mês.');
                } else if (resp.data.restanteEnvio === 1) {
                  setAttentionMessage('Resta ' + resp.data.restanteEnvio + ' envio de autenticação este mês.');
                }
              } else {
                setAttentionMessage('');
              }

              if (resp.data.situacaoReenvio === 'Nenhum') {
                setResponseReenvio('');
              } else if (resp.data.situacaoReenvio === 'Antigo') {
                setResponseReenvio('reenviar código');
              } else if (resp.data.situacaoReenvio === 'Novo') {
                setResponseReenvio('enviar novo código');
              }

              setModalAutenticacaoOpen(true);
            }
          }
        }
      }
    });
    return '';
  };

  const handleAut2 = (codigo: string) => {
    // debugger;
    if (codigo !== '') {
      const codigosAtivosStandard = produtosStandard.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
      const codigosInativosStandard = produtosStandard.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

      const codigosAtivosPremium = produtosPremium.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
      const codigosInativosPremium = produtosPremium.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

      const codigosAtivosNivel004 = produtosNivel004.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
      const codigosInativosNivel004 = produtosNivel004.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

      const codigosAtivosNivel005 = produtosNivel005.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
      const codigosInativosNivel005 = produtosNivel005.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

      const codigosAtivosNivel006 = produtosNivel006.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
      const codigosInativosNivel006 = produtosNivel006.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

      const codigosAtivosNivel007 = produtosNivel007.filter((prod) => prod.selecionado).map((prod) => prod.codigo);
      const codigosInativosNivel007 = produtosNivel007.filter((prod) => !prod.selecionado).map((prod) => prod.codigo);

      aut2FatService.autAplicarCodigo(codigo).then((resp) => {
        if (resp.data.situacaoAplicacao === 'Falha') {
          showAlertApi('Erro', 'error', resp.status);
          return;
        } else if (resp.data.situacaoAplicacao === 'Liberado') {
          sProdutos
            .selecionaProdutos({
              codigosAtivosStandard,
              codigosInativosStandard,
              codigosAtivosPremium,
              codigosInativosPremium,
              codigosAtivosNivel004,
              codigosInativosNivel004,
              codigosAtivosNivel005,
              codigosInativosNivel005,
              codigosAtivosNivel006,
              codigosInativosNivel006,
              codigosAtivosNivel007,
              codigosInativosNivel007,
            })
            .then((resp) => {
              // debugger;
              if (resp.data) {
                showAlertApi(resp.data, 'success', 5000);
              }

              refreshProdutos();
              setHouveAlteracao(false);
              setModalConfirmacaoOpen(false);

              setModalAutenticacaoOpen(false);
            });
        } else if (resp.data.situacaoAplicacao === 'CodigoInvalido') {
          showAlertApi('Codigo inválido', 'warning', 5000);
          return;
        } else if (resp.data.situacaoAplicacao === 'CodigoVencido') {
          showAlertApi('Codigo vencido', 'warning', 5000);
          return;
        }
      });
    }
    return '';
  };

  const buscarDescricaoProdutoSelecionado = async function (produto: IProduto) {
    setProdutoSelecionadoModalInfo(produto);
    let UrlProdutoIspExtra = "";
    let ProdutoIspExtraPaDescricao = "";

    if (produto.urlProdutoIspExtra != null) {
      UrlProdutoIspExtra = produto.urlProdutoIspExtra;
    }

    if (UrlProdutoIspExtra !== "" && ProdutoIspExtraPaDescricao !== "") {
      setProdutoSelecionadoUrlModalInfo(UrlProdutoIspExtra);
      setProdutoSelecionadoDescricaoModalInfo(ProdutoIspExtraPaDescricao);
      setModalInfoProdutoOpen(true);
    } else {
      const isp =
        produto.textoExplicativoProprio === TextoExplicativoProprio.Sim
          ? `_${produto.idIsp}`
          : "";

      const codProduto = produto.codigo.toString().padStart(8, "0");
      const xhttp = new XMLHttpRequest();
      xhttp.open(
        "GET",
        `./content/produto/descricao/prodin_info_${codProduto}${isp}.json`,
        true
      );

      await xhttp.send();

      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          try {
            const data: any = JSON.parse(xhttp.responseText);

            setProdutoSelecionadoUrlModalInfo(data.url);
            setProdutoSelecionadoDescricaoModalInfo(data.text);
          } catch {
            setProdutoSelecionadoDescricaoModalInfo("Sem descrição");
            setProdutoSelecionadoUrlModalInfo("");
          } finally {
            setModalInfoProdutoOpen(true);
          }
        }
      };
    }
  };

  const handleOpenApp = function (produto: IProduto) {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);
    
    if (isAndroid){
      if (produto.urlAndroidIspExtra){ 
        window.open(produto.urlAndroidIspExtra, '_blank');
      }else{      
        window.open(produto.urlAndroid, '_blank');
    }
    }else if (isIOS) {  
      if(produto.urlIOSIspExtra){
        window.open(produto.urlIOSIspExtra, '_blank');
      }else{
        window.open(produto.urlIOS, '_blank');
      }      
    } else {     
      if(produto.urlProdutoIspExtra){
        window.open(produto.urlProdutoIspExtra, '_blank');
      }else{
        window.open(produto.urlProduto, '_blank');
      }       
    }
  };

  // Executa scripts personalizados por ISP
  useEffect(() => {
    if (
      process.env.REACT_APP_CODIGO_ISP_WEBBY?.length !== 0 &&
      user?.isp?.codigoIsp === process.env.REACT_APP_CODIGO_ISP_WEBBY
    ) {
      const script = document.createElement('script');
      script.src = '../scripts/webbyChat.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const handleOpenModalOptionals = (optionalsType: string) => {
    const found = optionalsFetchData.find((item) => item.type === optionalsType);
    if (found) {
      setModalOptionalsData(found);
    }
  };

  const handleCancelModalOptionals = () => {
    setModalOptionalsData({ type: '', data: [] });
  };

  const handleSaveModalOptionals = (type: string, choiceId: string, chosenName: string) => {
    switch (type) {
      case 'NbaTeamChoice':
        sNba.atualizaEquipeEscolhida(choiceId).then((resp) => {
          if(resp != null){
            if (resp?.status === 200) {
              updateOptionalsChoice(type, choiceId, chosenName);
            }
          }
          
        }).catch((err) =>{
        });
        break;
      default:
        break;
    }
    setModalOptionalsData({ type: '', data: [] });
  };

  const updateOptionalsChoice = (type: string, choiceId: string, chosenName?: string) => {
    const previousSameTypeRemoved = modalOptionalChosen.filter((item) => item.type !== type);
    setModalOptionalChosen([...previousSameTypeRemoved, { type, id: choiceId, name: chosenName }]);
  };

  const fetchListOptionals = (products: Array<IProduto>) => {
    products.forEach((prod: IProduto) => {
      const type = prod.produtoOpcional.tipoProdutoOpcional;
      switch (type) {
        case 'NbaTeamChoice':
          sNba.listaEquipes().then((resp) => {
            if (resp.status === 200) {
              const data = resp.data.map((item: any) => ({ id: item.idNbaEquipe.toString(), nome: item.nomeEquipe }));
              setOptionalsFetchData([...optionalsFetchData, { type: 'NbaTeamChoice', data }]);
              const chosenId = prod.produtoOpcional.opcionais[0]?.idNbaEquipe;
              if (chosenId) {
                updateOptionalsChoice(
                  type,
                  chosenId.toString(),
                  data?.find((item: any) => item.id === chosenId.toString())?.nome,
                );
              }
            }
          });
          break;
        default:
          break;
      }
    });
  };

  const verifyOptionalSelected = () => {
    const allProducts = [...produtosNaoElegiveis, ...produtosStandard, ...produtosPremium, ...produtosNivel004, ...produtosNivel005, ...produtosNivel006, ...produtosNivel007];
    const found = allProducts.find(
      (item) => item.selecionado && item.produtoOpcional.tipoProdutoOpcional !== 'SemOpcional',
    );
    if (found) {
      const choice = modalOptionalChosen.find((item) => item.type === found.produtoOpcional.tipoProdutoOpcional);
      if (!choice) {
        handleOpenModalOptionals(found.produtoOpcional.tipoProdutoOpcional);
        return false;
      }
    }
    return true;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }



  return (
    <div className="pacote-page">
      <Container className="container">
        <div className="header-container">
          <div className="logout-container">
          <Tooltip title="Sair">
            <IconButton aria-label="upload picture" component="span" size="medium" onClick={() => logout()}>
              <ExitToAppOutlined className="botao-sair" />
            </IconButton>
          </Tooltip>
          </div>

          <div className="header-content">
            <img
              src={`./content/isp/images/servicos/isp_${user?.isp?.codigo}.png`}
              alt="Logo parceiro"
              className="header-image"
            />

            <div className="header-welcome">
              <Typography variant="h6" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} align="left">
                Olá, {user?.nome}
              </Typography>
            </div>
          </div>
        </div>
        <div id='pick-container' className="content-container">
          <div className="content-body">
          <Button
            id="navigator-button"
            className='breadcrumbs'
            style={{ color: invertRgbColor(colorContrast as string) }}
            aria-controls={open ? 'navigator-button' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              Navegação rápida
            </Button>
            <Menu
              id="navigator-button"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'navigator-button',
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
            >
                {produtosNaoElegiveis.length > 0 &&
                  <MenuItem className='content-container' onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('fixos')
                  }}>
                    Já no seu pacote
                  </MenuItem>
                }
                {(produtosStandard.length > 0 && informacoesQuantidadeStandard.totalPermitido > 0) &&
                  <MenuItem onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('standard')
                  }}>
                    Standard
                  </MenuItem>
                }
                {(produtosNivel004.length > 0 && informacoesQuantidadeNivel004.totalPermitido > 0) && 
                  <MenuItem className='content-container' onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('nivel004')
                  }}>
                    Advanced
                  </MenuItem>
                }
                {(produtosNivel005.length > 0 && informacoesQuantidadeNivel005.totalPermitido > 0) &&
                  <MenuItem className='content-container' onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('nivel005')
                  }}>
                    Premium Anúncio
                  </MenuItem>
                }
                {(produtosPremium.length > 0 && informacoesQuantidadePremium.totalPermitido > 0) && 
                  <MenuItem className='content-container' onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('premium')
                  }}>
                    Premium
                  </MenuItem>
                }
                {(produtosNivel006.length > 0 && informacoesQuantidadeNivel006.totalPermitido > 0) &&
                  <MenuItem className='content-container' onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('nivel006')
                  }}>
                    Premium Super
                  </MenuItem>
                }
                {(produtosNivel007.length > 0 && informacoesQuantidadeNivel007.totalPermitido > 0) &&
                  <MenuItem className='content-container' onClick={() => {
                    setAnchorEl(null)
                    scrollToElement('nivel007')
                  }}>
                    Premium Super Extra
                  </MenuItem>
                }
            </Menu>
            <Paper elevation={1} className="produto-lista-container">
              {produtosNaoElegiveis.length > 0 && 
                <div>
                  <div id='fixos' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="JÁ NO SEU PACOTE"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={false}
                    produtos={produtosNaoElegiveis}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={true}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                  />
                </div>
              }
              {(produtosStandard.length > 0 && informacoesQuantidadeStandard.totalPermitido > 0) && 
                <div>
                  <div id='standard' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="SELECIONE SEU(S) APLICATIVO(S) STANDARD"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={true}
                    produtos={produtosStandard}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={false}
                    informacoesQuantidade={informacoesQuantidadeStandard}
                    setProduto={setProdutosStandard}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                  />
                </div>
              }
              {(produtosNivel004.length > 0 && informacoesQuantidadeNivel004.totalPermitido > 0) &&
                <div>
                  <div id='nivel004' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="SELECIONE SEU(S) APLICATIVO(S) ADVANCED"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={true}
                    produtos={produtosNivel004}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={false}
                    informacoesQuantidade={informacoesQuantidadeNivel004}
                    setProduto={setProdutosNivel004}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                    />
                </div>
              }
              {(produtosNivel005.length > 0 && informacoesQuantidadeNivel005.totalPermitido > 0) &&
                <div>
                  <div id='nivel005' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="SELECIONE SEU(S) APLICATIVO(S) PREMIUM ANÚNCIO"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={true}
                    produtos={produtosNivel005}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={false}
                    informacoesQuantidade={informacoesQuantidadeNivel005}
                    setProduto={setProdutosNivel005}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                  />
                </div>
              }
              {(produtosPremium.length > 0 && informacoesQuantidadePremium.totalPermitido > 0) && 
                <div>
                  <div id='premium' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="SELECIONE SEU(S) APLICATIVO(S) PREMIUM"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={true}
                    produtos={produtosPremium}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={false}
                    informacoesQuantidade={informacoesQuantidadePremium}
                    setProduto={setProdutosPremium}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                  />
                </div>
              }
              {(produtosNivel006.length > 0 && informacoesQuantidadeNivel006.totalPermitido > 0) &&
                <div>
                  <div id='nivel006' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="SELECIONE SEU(S) APLICATIVO(S) PREMIUM SUPER"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={true}
                    produtos={produtosNivel006}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={false}
                    informacoesQuantidade={informacoesQuantidadeNivel006}
                    setProduto={setProdutosNivel006}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                  />
                </div>
              }
              {(produtosNivel007.length > 0 && informacoesQuantidadeNivel007.totalPermitido > 0) &&
                <div>
                  <div id='nivel007' style={{ marginBottom: '0.5rem' }} />
                  <AccordionProdutos
                    accordionName="SELECIONE SEU(S) APLICATIVO(S) PREMIUM SUPER EXTRA"
                    accordionExpanded={accordionExpanded}
                    handleChangeAccordionExpanded={handleChangeAccordionExpanded}
                    construirTotalizadores={true}
                    produtos={produtosNivel007}
                    handleOpenApp={handleOpenApp}
                    buscarDescricaoProdutoSelecionado={buscarDescricaoProdutoSelecionado}
                    alteracoesHabilitadas={alteracoesHabilitadas}
                    itensFixos={false}
                    informacoesQuantidade={informacoesQuantidadeNivel007}
                    setProduto={setProdutosNivel007}
                    handleSelectProduto={handleSelectProduto}
                    handleOptionalModal={handleOpenModalOptionals}
                    chosenOptionals={modalOptionalChosen}
                  />
                </div>
              }
              <div className="display-flex center" style={{ marginTop: 'auto' }}>
              <Zoom
                in={houveAlteracao && alteracoesHabilitadas}
                timeout={300}
              >
                <Fab type='submit' className='button-primary' variant="round" style={{ position: 'fixed', right: '46vw', bottom: '40px' }} onClick={selecionarPacotes} >
                  <SaveIcon />
                </Fab>
              </Zoom>
              </div>
            </Paper>
          </div>
        </div>
        <Zoom
            in={isActionButton}
            timeout={300}
          >
          <Fab className='button-primary' variant="round" style={{ position: 'fixed', right: '7vw', bottom: '40px' }} onClick={scrollToTop} >
            <UpIcon />
          </Fab>
        </Zoom>
        <ModalAut2fat
          open={modalAutenticacaoOpen}
          attentionMessage={attentionMessage}
          bodyStandard={modalConfirmacaoInformacoes.bodyStandard}
          bodyPremium={modalConfirmacaoInformacoes.bodyPremium}
          handleClose={() => setModalAutenticacaoOpen(false)}
          handleAut2={(codigo: string) => {
            return handleAut2(codigo);
          }}
          verifyAut2Fat={(operacao: string) => {
            return verifyAut2Fat(operacao);
          }}
          bodyText={bodyTextContent}
          responseReenvio={responseReenvio}
        />

        <ModalMessage
          open={modalMessageOpen}
          headerMessage={intTitleModalMessage}
          bodyMessage={intTextModalMessage}
          handleClose={() => setModalMessageOpen(false)}
        />
        <ModalConfirm
          open={modalConfirmacaoOpen}
          headerMessage={modalConfirmacaoInformacoes.headerText}
          bodyStandard={modalConfirmacaoInformacoes.bodyStandard}
          bodyPremium={modalConfirmacaoInformacoes.bodyPremium}
          bodyNivel004={modalConfirmacaoInformacoes.bodyNivel004}
          bodyNivel005={modalConfirmacaoInformacoes.bodyNivel005}
          bodyNivel006={modalConfirmacaoInformacoes.bodyNivel006}
          bodyNivel007={modalConfirmacaoInformacoes.bodyNivel007}
          handleClose={() => setModalConfirmacaoOpen(false)}
          handleConfirm={() => {
            verifyAut2Fat('Abrir');
          }}
        />

        <ModalInfo
          open={modalInfoProdutoOpen}
          headerMessage={`${produtoSelecionadoModalInfo?.nameVerify}`}
          bodyMessage={produtoSelecionadoDescricaoModalInfo}
          url={produtoSelecionadoUrlModalInfo}
          handleClose={() => setModalInfoProdutoOpen(false)}
        />

        <ModalOptionals
          open={modalOptionalsData.data.length > 0}
          optionals={modalOptionalsData}
          handleCancel={handleCancelModalOptionals}
          handleSave={handleSaveModalOptionals}
          chosen={modalOptionalChosen}
        />
      </Container>
    </div>
  );
};

export default PartnersPage;

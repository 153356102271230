import { Avatar, Button, Divider, Switch, Typography } from "@material-ui/core";
import DetailsIcon from "@material-ui/icons/Details";
import { v4 } from "uuid";

import { InfoOutlined } from "@material-ui/icons";
import { Fragment } from "react";
import IAccordionProdutos from "../interfaces/IAccordionProdutos";
import { InitialValues } from "../interfaces/IInformacoesQuantidadeProdutos";
import IProduto from "../interfaces/produto";

const AccordionProdutos: React.FunctionComponent<IAccordionProdutos> = ({
  accordionName,
  accordionExpanded,
  handleChangeAccordionExpanded,
  construirTotalizadores,
  produtos,
  informacoesQuantidade = InitialValues,
  handleOpenApp,
  buscarDescricaoProdutoSelecionado,
  alteracoesHabilitadas,
  setProduto = () => {},
  handleSelectProduto,
  itensFixos,
  handleOptionalModal = () => {},
  chosenOptionals,
}) => {
  //Produtos para estarem sempre desativados em sua seleção
  //Defina os códigos aqui
  const readOnlyProducts = [0];

  const isWarningSelectionsLimit = (checked: boolean) => {
    if(!checked) return false;

    const { selecionado, totalPermitido } = informacoesQuantidade;
    const newSelectedCount = selecionado + 1;
  
    return newSelectedCount > totalPermitido;
  };

  const construirSwitch = (prod: IProduto) => {
    //Produtos para estarem sempre desativados em sua seleção
    let productReadOnly = readOnlyProducts.includes(prod.codigo);
    if (prod.selecionado && productReadOnly) {
      prod.selecionado = false;
    }

    if (!itensFixos) {
      return (
        <div>
          <Switch
            disabled={!alteracoesHabilitadas || productReadOnly}
            checked={prod.selecionado}
            onChange={(e) =>
              handleSelectProduto(
                e,
                informacoesQuantidade.disponivel,
                setProduto,
                prod.descricao,
                isWarningSelectionsLimit(e.target.checked),
              )
            }
            className="produto-switch"
          />
        </div>
      );
    }

    return (
      <div className="produto-incluso-container">
        <Typography variant="subtitle2">Já incluso</Typography>
      </div>
    );
  };

  const buildOptionalsButton = (prod: IProduto) => {
    const getCustomSelectText = (type: string) => {
      switch (type) {
        case "NbaTeamChoice":
          return "Selecione a Equipe NBA";
        default:
          return "";
      }
    };
    const hasOptionals = (type: string) => {
      switch (type) {
        case "SemOpcional":
          return false;
        default:
          return true;
      }
    };
    const optionalsType = prod.produtoOpcional.tipoProdutoOpcional;
    const chosenOpt = chosenOptionals.find(
      (item) => item.type === optionalsType
    );
    return (
      hasOptionals(optionalsType) && (
        <div className="optional-container">
          {chosenOpt ? (
            <Avatar
              src={`./content/produto/images/${optionalsType}-opcionais/${optionalsType}_${chosenOpt.id}.jpg`}
              className={`optional-chosen-logo ${
                !prod.selecionado ? "optional-logo-disabled" : ""
              }`}
              variant="square"
              alt={"Avatar"}
            />
          ) : (
            <div className="optional-chosen-logo"></div>
          )}
          <Button
            className={`optional-button ${
              !prod.selecionado ? "optional-button-disabled" : ""
            }`}
            color="primary"
            onClick={() => handleOptionalModal(optionalsType)}
            disabled={!prod.selecionado}
            fullWidth
          >
            <Typography className="optional-button-name" variant="body1">
              {chosenOpt ? chosenOpt.name : getCustomSelectText(optionalsType)}
            </Typography>
            <DetailsIcon fontSize="inherit" />
          </Button>
        </div>
      )
    );
  };

  const excededLimitErrorColor = informacoesQuantidade.selecionado > informacoesQuantidade.totalPermitido ? "error" : "inherit"

  return (
    <div style={{ minHeight: '200px' }}>
      <div className="content-product-container">
        <h4
          className="accordion-header-title accordion-header-background"
        >
          {accordionName}
        </h4>
        {construirTotalizadores && (
          <div
            className="content-header"
            style={{ gap: 20, marginBottom: 10, borderEndEndRadius: 5, borderEndStartRadius: 5 }}
          >
            <Typography variant="subtitle2" style={{ lineHeight: 1.4 }}>
              Total Permitido: {informacoesQuantidade.totalPermitido}
            </Typography>
            <Typography variant="subtitle2" style={{ lineHeight: 1.4 }} color={excededLimitErrorColor}>
              Ativos: {informacoesQuantidade.selecionado}
            </Typography>
            <Typography variant="subtitle2" style={{ lineHeight: 1.4 }}>
              Disponível:{" "}
              {informacoesQuantidade.disponivel < 0
                ? 0
                : informacoesQuantidade.disponivel}
            </Typography>
          </div>
        )}

        <div className="produto-lista">
          {produtos.map((produto) => {
            return (
              <Fragment key={v4()}>
                <div key={produto.codigo} className="produto-card-container">
                  <div className="produto-card">
                    <div
                      className="produto-image"
                      onClick={() => handleOpenApp(produto)}
                    >
                      <Avatar
                        src={
                          produto.logoProprio
                            ? `./content/produto/images/produto_${produto.codigo}_${produto.idIsp}.png`
                            : `./content/produto/images/produto_${produto.codigo}.png`
                        }
                        className="produto-logo"
                        variant="rounded"
                        alt={
                          produto.textoExplicativoProprio
                            ? produto.produtoIspExtraPaDescricao
                            : produto.descricao
                        }
                      />
                    </div>

                    <InfoOutlined
                      className="produto-info-icon"
                      onClick={() => buscarDescricaoProdutoSelecionado(produto)}
                    />

                    <div className="produto-title-container">
                      <Typography variant="body1">
                        {produto.nameVerify ? produto.nameVerify : ""}
                      </Typography>
                    </div>

                    <div className="produto-switch-container">
                      {construirSwitch(produto)}
                    </div>
                  </div>
                  <div className="produto-card">
                    {buildOptionalsButton(produto)}
                  </div>
                </div>
                <Divider />
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccordionProdutos;
